.wd-layout[data-v-2308e082] {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
}
.wd-layout .wd-layout__content[data-v-2308e082] {
  background: url(../img/bg.c5a15730.png) top center no-repeat;
  background-size: 100% 1.5rem;
  padding: 0 0.16rem 0.16rem;
}
.wd-layout .wd-layout__content .wd-title[data-v-2308e082] {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.18rem;
  font-weight: bold;
  text-align: center;
}
.wd-layout .wd-layout__content .wd-layout__sortmenu[data-v-2308e082] {
  height: calc(100% - 0.8rem);
  background: #fff;
  border-radius: 0.12rem;
}
.wd-layout .wd-layout__content .van-grid[data-v-2308e082] {
  border-radius: 0.12rem;
  overflow: hidden;
}
.wd-layout__header[data-v-2308e082] {
  height: 0.46rem;
  width: 100%;
}
.wd-layout__content[data-v-2308e082] {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.05rem 0;
}
.wd-layout__content .icon-image[data-v-2308e082] {
  height: 0.28rem;
  margin-bottom: 0.05rem;
}
.wd-layout__footer[data-v-2308e082] {
  height: 0.5rem;
}
